import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Cta1 from "../components/home/cta1"
import Cta2 from "../components/home/cta2"
import FeaturedProjects from "../components/home/featuredProjects"
import MegaBlob from "../components/home/megaBlob"
import ScrollText from "../components/home/scrollText"
//import Testimonials from "../components/home/testimonials"

import Mini1 from '../images/blobs/mini1.inline.svg'
// import Mini2 from '../images/blobs/mini2.inline.svg'
import Large1 from '../images/blobs/large1.inline.svg'

const PageHome = ({ data }) => {
  const page = data.wpPage

  return (
    <Layout id="home">
      <Seo
        title={page.title}
        seoTitle={page.SeoMeta.seoTitle}
        description={page.SeoMeta.seoDescription}
      />

      <MegaBlob h1={page.HomeData.h1} />
      <Cta1 />

      <div className="page-dark">
        {/* <div className="blob-wrap">
          <Mini2 style={{right: '-33%', transform: 'translateY(-35%)'}} />
        </div> */}
        <div className="blob-wrap">
          <Mini1 style={{left: '-33%', transform: 'translateY(65%)'}} />
        </div>
        <ScrollText copy={page.HomeData.scrollText} />
          {/*<Cta2 />*/}
        <div className="blob-wrap">
          <Large1 style={{left: '50%', transform: 'translate(-50%, -10%)', width: '200%'}} />
        </div>
        <FeaturedProjects />
      </div>


    </Layout>
  )
}

export default PageHome

export const query = graphql`
  query($slug: String) {
    wpPage(slug: { eq: $slug }) {
      title
      HomeData {
        h1
        scrollText
      }
      SeoMeta {
        seoTitle
        seoDescription
      }
    }
  }`
