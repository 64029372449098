import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import * as style from './home.module.scss'

const Cta1 = () => {
  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: {eq: "home"}) {
        HomeData {
          cta1 {
            title
            copy
            link {
              ... on WpPage {
                link
              }
            }
          }
        }  
      }
    }
  `)
  const cta = data.wpPage.HomeData.cta1

  return (
    <section id={style.cta1}>
      <div className={style.cta1Inner}>
        <h2>{cta.title}</h2>
        <p>{cta.copy}</p>
        <Link to={cta.link.link} className="mh-button">
          Dowiedz się więcej
        </Link>
      </div>
    </section>
  )
}

export default Cta1
