import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import throttle from "lodash.throttle"
import * as style from './home.module.scss'

class ScrollText extends React.Component {
  constructor({ copy }) {
    super()
    this.state = {
      boldText: []
    }
    
    this.handleScroll = throttle(this.handleScroll.bind(this), 20)
  }

  getRatio = (min, max, r) => {
    const range = Math.round((max - min) * r)
    return Math.abs(range + min)
  }

  handleScroll() {
    // for each bold tag:
    // - is it visible in window?
    // - the closer to the center the closer to wght 1000 & wdth 0
    // - closer to top or bottom, closer to wght 100 & wdth 500
    this.state.boldText.forEach(text => {
      const rect = text.getBoundingClientRect()
      const bottom = window.innerHeight - rect.height
      // if it's visible in the window
      if (rect.top >= 0 && rect.top <= bottom) {
        const ratio = Math.abs(Math.abs((rect.top / bottom - 0.5) * 2) - 1)
        const wght = this.getRatio(100, 900, ratio)
        const wdth = this.getRatio(500, 420, ratio)
        text.style.fontVariationSettings = `'wght' ${wght}, 'wdth' ${wdth}`
      }
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    this.setState({
      boldText: [...document.getElementsByTagName(`b`)]
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    return (
      <section id={style.scrollText}>
        <div className="container">
          <div className={style.wrap} dangerouslySetInnerHTML={{ __html: this.props.copy }} />
          <Link to="/contact/" className="mh-button invert">Skontaktuj się</Link>
        </div>
      </section>
    )  
  }
}

ScrollText.propTypes = {
  copy: PropTypes.string.isRequired
}

export default ScrollText
