import React from 'react'
import PropTypes from 'prop-types'

import debounce from "lodash.debounce"
import throttle from "lodash.throttle"
import * as style from './megablob.module.scss'
import logo from '../../images/logo.svg'
import BlobMobile from '../../images/blobs/hero.static.inline.svg'
import BlobDesktop from '../../images/blobs/hero.inline.svg'

class MegaBlob extends React.Component {
  constructor({ h1 }) {
    super()
    this.state = {
      isMobile: undefined,
      ratio: 0
    }
    
    this.handleScroll = throttle(this.handleScroll.bind(this), 20)
    this.handleResize = debounce(this.handleResize.bind(this), 350)
  }

  handleScroll() {
    let ratio = window.scrollY / ((window.innerHeight / 2) + 125)
    if (ratio > 1) {
      ratio = 1
    }

    this.setState({
      ratio: ratio
    })
  }

  handleResize() {
    // this.forceUpdate()
    this.setState({
      isMobile: window.matchMedia(`(max-width: 767px)`).matches
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const left = `${-10 * this.state.ratio}%`
    const transform = `scale(${-0.1 * this.state.ratio + 1})`
    const classes = [style.heroBlobWrap]
    if (this.state.ratio === 1) {
      classes.push(style.pinned)
    }

    return (
      <section id={style.megaBlob}>
        <div className={style.megaBlobInner}>
          <img src={logo} className={style.logo} alt="Mediahub" />
          <h1>{this.props.h1}</h1>
        </div>

        <div className={classes.join(' ')}>
          { typeof this.state.isMobile === `boolean` && !this.state.isMobile && (
            <BlobDesktop style={{position:'relative', left:left, transform:transform}} />
          )}
          { typeof this.state.isMobile === `boolean` && this.state.isMobile && (
            <BlobMobile style={{width:'225%', transform:'translate(-40%, -10%)'}} />
          )}
        </div>
      </section>
    )
  }
}

MegaBlob.propTypes = {
  h1: PropTypes.string.isRequired
}

export default MegaBlob
