import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import FeaturedProject from './featuredProject'
import * as style from './featuredprojects.module.scss'

const FeaturedProjects = () => {
  const data = useStaticQuery(graphql`{
  wpPage(slug: {eq: "home"}) {
    HomeData {
      featuredProjects {
        ... on WpProject {
          title
          link
          Description {
            description
          }
          ProjectData {
            client {
              ... on WpClient {
                title
              }
            }
            offices {
              ... on WpOffice {
                OfficeData {
                  continent
                }
              }
            }
          }
          ProjectHero {
            heroVideo
            heroImage {
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 960, layout: FULL_WIDTH)
                }
              }
            }
            sidekickImage {
              altText
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 640, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
}
`)
  const projects = data.wpPage.HomeData.featuredProjects

  return(
      <div></div>
  )

  // return (
  //   <section id={style.featuredProjects}>
  //     <h2>Nasze prace</h2>
  //     {projects.map(project => <FeaturedProject {...project} key={project.title} />)}
  //     <div className="mh-button-wrap">
  //       <h4>Podoba Ci się to, co widzisz?</h4>
  //       <Link to="/work/" className="mh-button invert">Zobacz więcej realizacji</Link>
  //     </div>
  //   </section>
  // )
}

export default FeaturedProjects
