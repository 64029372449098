// extracted by mini-css-extract-plugin
export var cta1 = "home-module--cta1--QLeM9";
export var cta1Inner = "home-module--cta1-inner--TK-MO";
export var cta2 = "home-module--cta2--g2ugi";
export var cta2ColCopy = "home-module--cta2-col-copy--0AwDM";
export var cta2ColImage = "home-module--cta2-col-image--HYuNS";
export var cta2Inner = "home-module--cta2-inner--OvIsu";
export var item = "home-module--item--OjOjr";
export var scrollText = "home-module--scroll-text--45z8U";
export var testimonials = "home-module--testimonials--lxC4i";
export var title = "home-module--title--IrPc7";
export var wrap = "home-module--wrap--ejAfz";